<template>
	<div style="width: 100%;height: 22rem;">
		<div class="banner">
			<!-- banner_image -->
			<img class="banner" :src="banner" alt="" srcset="" />
			<!-- banner_文字 -->
			<div class="banner1">
				<div>{{banner1}}</div>
				<div>{{banner2}}</div>
			</div>
			<!-- 顶部导航 -->
			<div class="top_nav">
				<!-- 导航_logo -->
				<div class="top_nav_l">
					<img src="../../src/assets/logo.png" alt="" srcset="" />
					<div>领科视界</div>
				</div>
				<!-- 导航_tab -->
				<div class="top_nav_c">
					<div @click="select(1)" :class="bot==1?'aaa':'bbb'"><a href="#abouts">{{$t('m.aboutus')}}</a></div>
					<div @click="select(2)" :class="bot==2?'aaa':'bbb'"> <a href="#services">{{$t('m.ourfw')}}</a></div>
					<div @click="select(3)" :class="bot==3?'aaa':'bbb'"><a href="#commitment"> {{$t('m.ourcn')}}</a>
					</div>
					<div @click="select(4)" :class="bot==4?'aaa':'bbb'"><a href="#contact">{{$t('m.ourlx')}}</a></div>
				</div>
				<!-- 导航对语言 -->
				<div class="top_nav_r">
					<div @click="changeLangEvent(1)">中</div>
					<div></div>
					<div @click="changeLangEvent(2)">繁</div>
					<div></div>
					<div @click="changeLangEvent(3)">English</div>
				</div>
			</div>
		</div>
		<div class="big_box">
			<div class="about_us" id="abouts" ref='ref1'>
				<div class="about_us_d">
					<img class="about_us_d_img1" src="../../src/assets/yinhao.png" alt="" srcset="" />
					<div class="about_us_E">About us</div>
					<div class="about_us_C">{{about_name}}</div>
					<div class="about_us_X"></div>
					<div class="about_us_N" v-html="about"></div>
					<img class="about_us_d_img2" src="../../src/assets/Designed.png" alt="" srcset="" />
					<img class="about_us_d_img3" :src="ren1" alt="" srcset="" />
				</div>
			</div>
			<div class="ourserver" id="services" ref='ref2'>
				<img class="ourserver_img1" :src="ren2" alt="" />
				<div style="position: absolute;width: 58%;right: 1rem;top:-0.4rem;">
					<img class="ourserver_Y" src="../../src/assets/yinhao.png" alt="" srcset="" />
					<div class="ourserver_E">Our Services</div>
					<div class="ourserver_C">{{fw_name}}</div>
					<div class="ourserver_X"></div>
					<div class="ourserver_N" v-html="fw"></div>


				</div>
				<img class="ourserver_imgs" src="../../src/assets/pic1.png" alt="" />

				<img class="ourserver_img2" src="../../src/assets/Designed.png" alt="" srcset="" />
			</div>
			<div class="di_bgi">
				<div class="commitment_box" id="commitment" ref='ref3'>
					<img class="commitment_Y" src="../../src/assets/yinhao.png" alt="" srcset="" />
					<div class="commitment_E">Our commitment</div>
					<div class="commitment_C">{{cn_name}} </div>
					<div class="commitment_X"></div>
					<div class="commitment_N" v-html="cn"></div>
				</div>
				<div class="commitment_box1" id="contact" ref='ref4'>
					<img class="commitment_Y" src="../../src/assets/yinhao.png" alt="" srcset="" />
					<div class="commitment_E">Contact us</div>
					<div class="commitment_C">{{lx_name}} </div>
					<div class="commitment_X"></div>
					<div class="commitment_N" v-html="lx"></div>

				</div>
				<img class="di_bgi_img1" :src="ren3" alt="" />
				<img class="di_bgi_img2" :src="ren4" alt="" srcset="" />
			</div>
			<div class="lxfsbox">
				<div class="lxfsbox_b">
					<div class="lxfsbox_b_l"></div>
					<div class="lxfsbox_b_c">
						{{$t('m.lxfs')}}
					</div>

					<div class="lxfsbox_b_r"></div>
				</div>
				<div class="lxfsbox_i">
					<img src="../assets/youjian.png" alt="" @click="showModal=true" />
					<img src="../../src/assets/weixin.png" @click="showModal1=true" alt="" />
					<img src="../../src/assets/fasongx.png" @click="showModal2=true" alt="" />
				</div>
			</div>
		</div>
		<div class="modal" v-if="showModal">
			<div class="overlay"></div>
			<div class="content">
				<div class="content_box">
					<i @click="showModal=false" class="el-icon-close"
						style="position: absolute;right: 0.5rem;top: 0rem;font-size: 0.4rem;"></i>
					<div class="content_box_t">
						<div>{{yx_name}}</div>
						<div></div>
					</div>
					<div class="content_box_di">
						<div style="padding-top: 0.5rem;">{{$t('m.fslx')}}：</div>

						<div style="margin-top: 0.2rem;"><span>{{$t('m.yx')}}</span>

							：{{yx_num}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" v-if="showModal1">
			<div class="overlay"></div>
			<div class="content1">
				<div class="content1_box_t">
					<div>{{wx_name}}</div>
					<div></div>
				</div>
				<i @click="showModal1=false" class="el-icon-close"
					style="position: absolute;right: 1rem;top: 1rem;font-size: 0.4rem;"></i>
				<img class="image1_content1" :src="wx_image" alt="" srcset="" />
				<div class="image1_wx">【
					<span>{{$t('m.wx')}}</span>: {{wx_num}} 】
				</div>

			</div>
		</div>
		<div class="modal" v-if="showModal2">
			<div class="overlay"></div>
			<div class="content1">
				<div class="content1_box_t">
					<div>{{tel_name}}</div>
					<div></div>
				</div>
				<i @click="showModal2=false" class="el-icon-close"
					style="position: absolute;right: 1rem;top: 1rem;font-size: 0.4rem;"></i>
				<img class="image1_content1" :src="tel_image" alt="" srcset="" />
				<div class="image1_wx">【<span>{{$t('m.db')}}</span>
					:{{tel_num}}】
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		dataconfig
	} from "../../api/home.js";
	export default {
		data() {
			return {
				gdwidth: 0,
				ren1: "",
				ren2: "",
				ren3: "",
				ren4: "",
				banner: "",
				banner1: "",
				banner2: "",
				bot: 1,
				showModal1: false, // 控制弹窗显示/隐藏
				showModal2: false, // 控制弹窗显示/隐藏
				showModal: false, // 控制弹窗显示/隐藏
				type: 1,
				about: "",
				cn: "",
				fw: "",
				lx: "",
				wx_name: "",
				wx_num: "",
				wx_img: "",
				yx_name: "",
				yx_num: "",
				tel_image: "",
				tel_num: "",
				tel_name: "",
				about_name: "",
				cn_name: '',
				lx_name: "",
				fw_name: "",
				wx_image: "",

			}
		},
		beforeDestroy() {
			// 组件销毁前移除滚动事件监听
			window.removeEventListener('scroll', this.handleScroll);
		},

		mounted() {
			let width = window.innerWidth
			this.gdwidth = window.innerWidth
			console.log(width, "width")
			window.addEventListener('scroll', this.handleScroll);
			if (!localStorage.getItem('type')) {
				localStorage.setItem('type', 1)
				this.dataconfig()
			} else {
				this.type = localStorage.getItem('type')
				this.dataconfig()
			}
			if (localStorage.getItem('type') == 1) {
				this.lang = "zh-CN";

				this.$i18n.locale = this.lang; //关键语句
			} else if (localStorage.getItem('type') == 2) {
				this.lang = "en-F";
				this.$i18n.locale = this.lang; //关键语句
			} else if (localStorage.getItem('type') == 3) {
				this.lang = "en-US";

				this.$i18n.locale = this.lang; //关键语句
			}
		},
		methods: {

			changeLangEvent(e) {
				this.type = localStorage.setItem('type', e)
				if (e == 1) {
					this.lang = "zh-CN";

					this.$i18n.locale = this.lang; //关键语句
				} else if (e == 2) {
					this.lang = "en-F";
					this.$i18n.locale = this.lang; //关键语句
				} else if (e == 3) {
					this.lang = "en-US";

					this.$i18n.locale = this.lang; //关键语句
				}
				this.dataconfig()
			},
			handleScroll() {
				const div1 = this.$refs.ref1;
				const div3 = this.$refs.ref3;
				const div4 = this.$refs.ref4;
				const div2 = this.$refs.ref2;
				const divTop1 = div1.getBoundingClientRect().top;
				const divTop2 = div2.getBoundingClientRect().top;
				const divTop3 = div3.getBoundingClientRect().top;
				const divTop4 = div4.getBoundingClientRect().top;
				console.log(divTop4, 1)
				console.log(divTop2, 2)
				if (divTop1 > 300) {
					this.bot = 1
				}
				if (divTop2 < 300) {
					this.bot = 2
				}
				if (divTop3 < 300) {
					this.bot = 3
				}
				if (divTop4 < 300) {
					this.bot =4
				}
				// // 获取滚动条位置
				// const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				// // 根据滚动条位置进行逻辑处理
				// console.log('Current scroll position:', window.pageYOffset, document.documentElement.scrollTop, document
				// 	.body.scrollTop, scrollTop);
				// if (this.gdwidth <= 1280) {
				// 	if (scrollTop > 860) {
				// 		this.bot = 2
				// 	}
				// 	if (scrollTop < 800) {
				// 		this.bot = 1
				// 	}
				// 	if (scrollTop > 1500) {
				// 		this.bot = 3
				// 	}
				// 	if (scrollTop > 2200) {
				// 		this.bot = 4
				// 	}
				// } else if (this.gdwidth > 1200) {
				// 	if (scrollTop > 1000) {
				// 		this.bot = 2
				// 	}
				// 	if (scrollTop < 1000) {
				// 		this.bot = 1
				// 	}
				// 	if (scrollTop > 2000) {
				// 		this.bot = 3
				// 	}
				// 	if (scrollTop > 3500) {
				// 		this.bot = 4
				// 	}
				// }

			},
			// language(e) {
			// 	this.type = localStorage.setItem('type', e)
			// 	this.type = localStorage.getItem('type')
			// 	this.dataconfig()
			// },
			dataconfig() {
				dataconfig({
					type: localStorage.getItem('type')
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						console.log(res)
						this.about = res.data[7].content
						this.about_name = res.data[7].name
						this.cn = res.data[5].content
						this.cn_name = res.data[5].name
						this.fw = res.data[6].content
						this.fw_name = res.data[6].name
						this.lx = res.data[4].content
						this.lx_name = res.data[4].name
						this.wx_name = res.data[3].name
						this.wx_num = res.data[3].content
						this.wx_image = res.data[3].image
						this.tel_name = res.data[1].name
						this.tel_num = res.data[1].content
						this.tel_image = res.data[1].image
						this.yx_name = res.data[2].name
						this.yx_num = res.data[2].content
						this.banner = res.data[0].image
						this.banner1 = res.data[0].name
						this.banner2 = res.data[0].content
						this.ren1 = res.data[7].image
						this.ren2 = res.data[6].image
						this.ren3 = res.data[5].image
						this.ren4 = res.data[4].image
					}
				})
			},
			select(e) {
				this.bot = e
			},
			openModal() {
				this.showModal = true;
			},

			closeModal() {
				this.showModal = false;
			}
		},

	}
</script>
<style scoped>
	.aaa {
		border-bottom: 0.02rem solid #FFFFFF;

	}

	.bbb {
		border-bottom: 0.02rem solid #193649;

	}

	.top_nav {
		width: 100%;
		height: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		background-color: #193649;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 999;
	}

	.top_nav_l {
		width: 25%;
		display: flex;
		height: 1.2rem;
		align-items: center;
	}

	.top_nav_l>img {
		width: 1.2rem;
		height: 0.9rem;
	}

	.top_nav_l>div {
		font-family: Alimama ShuHeiTi;
		font-weight: bold;
		font-size: 0.40rem;
		color: #FEFFFF;
	}

	.top_nav_c {
		width: 47%;
		margin-left:1.5rem;
		display: flex;
		align-items: center;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #FFFFFF;

	}

	.top_nav_c>div {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.29rem;
		color: #FFFFFF;
		margin-right: 0.5rem;
		height: 0.7rem;
	}

	.top_nav_c>div>a {
		text-decoration: none;
		color: #FFF;
		cursor: pointer;
	}

	.top_nav_r {
		width: 12%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: Adobe Heiti Std;
		font-weight: normal;

		color: #FFFFFF;

	}

	.top_nav_r>div:nth-child(odd) {
		cursor: pointer;
		color: #fff;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.29rem;
		color: #FFFFFF;
	}

	.top_nav_r>div:nth-child(even) {
		width: 0.02rem;
		height: 0.3rem;
		background-color: #fff;
	}

	.banner {
		width: 100%;
		height: 10.2rem;
		position: relative;
	}

	.banner1 {
		position: absolute;
		z-index: 9;
		left: 49%;
		top: 3.2rem;
	}

	.banner1>div:nth-of-type(1) {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 0.7rem;
		color: #193649;

	}

	.banner1>div:nth-of-type(2) {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 0.60rem;
		color: #193649;
		margin-top: 0.4rem;

	}

	.big_box {
		width: 100%;
		height: 41.3rem;
		background-image: url(../../src/assets/bgi.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
		padding-top: 0.1rem;
	}

	::-webkit-scrollbar {
		display: none;
	}

	.about_us {
		width: 100%;
		height: 6rem;
		background-image: url(../../src/assets/471709201261_.pic.png);
		position: relative;
	}

	.about_us_d_img1 {
		margin-left: 1.2rem;
		width: 0.80rem;
		height: 0.34rem;

	}

	.about_us_d_img2 {
		width: 3.2rem;
		height: 0.44rem;
		position: absolute;
		bottom: 0.4rem;
		left: 0.4rem;
	}

	.about_us_d_img3 {
		width: 4.57rem;
		height: 10.37rem;
		position: absolute;
		bottom: -4.8rem;
		right: 0.6rem;
	}

	.about_us_E {
		margin-left: 1.85rem;
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		font-size: 0.64rem;
		color: #FFFFFF;
		opacity: 0.5;
		margin-top: -0.4rem;
	}

	.about_us_C {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.38rem;
		color: #FFFFFF;
		margin-left: 1.85rem;
	}

	.about_us_N {
		width: 45%;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.26rem;
		color: #FFFFFF;
		margin-left: 1.85rem;
		margin-top: 0.2rem;
	}


	.about_us_X {
		width: 0.22rem;
		height: 0.04rem;
		margin-left: 1.85rem;
		background-color: #FFFFFF;
		margin-top: 0.1rem;
	}

	.ourserver {
		width: 100%;
		height: 12.45rem;
		background-image: url(../../src/assets/431709200557.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}

	.ourserver_img1 {
		width: 6.59rem;
		height: 9.13rem;
		margin-top: 1.5rem;
		margin-left: 0.4rem;

	}

	.ourserver_Y {

		width: 0.81rem;
		height: 0.35rem;
	}

	.ourserver_E {
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		font-size: 0.64rem;
		color: #FFFFFF;
		line-height: 0.58rem;
		opacity: 0.5;
		margin-left: 0.6rem;
	}

	.ourserver_C {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.40rem;
		color: #FFFFFF;

		margin-left: 0.6rem;
	}

	.ourserver_X {
		width: 0.20rem;
		height: 0.04rem;

		margin-top: 0.2rem;
		margin-left: 0.1rem;
		background-color: #fff;
		margin-left: 0.7rem;
	}

	.ourserver_N {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.28rem;
		color: #FFFFFF;
		margin-top: 0.25rem;
		margin-left: 0.6rem;
	}

	.ourserver_img2 {
		position: absolute;
		bottom: 0.3rem;
		right: 0.3rem;

	}

	.di_bgi {
		width: 100%;
		background-image: url(../../src/assets/481709201297.png);
		height: 22.81rem;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}

	.commitment_box {
		position: absolute;
		left: 0.8rem;
	}

	.commitment_Y {
		width: 0.81rem;
		height: 0.35rem;
	}

	.commitment_E {
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		font-size: 0.64rem;
		color: #FFFFFF;
		line-height: 0.58rem;
		opacity: 0.5;
		margin-left: 0.5rem;
	}

	.commitment_C {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.40rem;
		color: #FFFFFF;

		margin-left: 0.5rem;
	}

	.commitment_X {
		width: 0.22rem;
		height: 0.04rem;
		margin-left: 0.6rem;
		background-color: #FFFFFF;
		margin-top: 0.1rem;
	}

	.commitment_N {
		width: 46%;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.28rem;
		color: #FFFFFF;
		margin-top: 0.25rem;
		margin-left: 0.6rem;
	}

	.di_bgi_img2 {
		width: 9rem;
		height: 10rem;
		position: absolute;
		right: 0rem;
		bottom: 0rem;
	}

	.di_bgi_img1 {
		width: 5.72rem;
		height: 9.87rem;
		position: absolute;
		right: 1.28rem;
		top: 0.1rem;

	}

	.commitment_box1 {
		position: absolute;
		left: 0.8rem;
		bottom: 5.56rem;
	}

	.lxfsbox {
		width: 6rem;
		position: absolute;
		bottom: 1.8rem;
		left: 1.20rem;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.lxfsbox_b {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.lxfsbox_b_l {
		width: 0.6rem;
		height: 0.05rem;
		background: linear-gradient(270deg, #193649 0%, rgba(170, 192, 210, 0) 100%);
	}

	.lxfsbox_b_c {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.30rem;
		color: #193649;
	}

	.lxfsbox_b_r {
		width: 0.6rem;
		height: 0.05rem;
		background: linear-gradient(90deg, #193649 0%, rgba(170, 192, 210, 0) 100%);
	}

	.lxfsbox_i {
		width: 4.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.6rem;
	}

	.lxfsbox_i>img {
		width: 0.86rem;
		height: 0.86rem;
		cursor: pointer;

	}

	.popup1 {}
</style>
<style lang="scss">
	.el-dialog__header {
		// display: none !important;
		padding: 0 !important;
		border-radius: 20px !important;

	}

	.el-dialog__body {
		padding: 0 !important;
		border-radius: 20px !important;

	}

	.class1 {
		border-radius: 100px;
	}
</style>
<style scoped>
	/* CSS样式 */
	.modal {
		position: fixed;
		/* 将弹窗设为fixed定位，保持居中显示 */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.overlay {
		width: 100%;
		height: 100vh;
		position: fixed;
		/* 将弹窗设为fixed定位，保持居中显示 */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .5);
		/* 半透明遮罩层 */
		z-index: -1;
		/* 放到最底部 */
	}

	.content {
		width: 13.07rem;
		height: 6.26rem;
		background-image: url(../../src/assets/popup.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content1 {
		width: 13.07rem;
		height: 9rem;
		background-image: url(../../src/assets/333.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	button {
		margin-top: 10px;
	}

	.content_box {
		width: 11.5rem;
		height: 5rem;
		position: relative;
	}

	.content_box_di {
		width: 11.33rem;
		height: 2.27rem;
		background: RGBA(241, 246, 248, .4);
		border-radius: 0.38rem;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.33rem;
		color: #888888;
		margin-top: 0.8rem;
	}

	.content_box_di>div {
		margin-left: 0.5rem;

	}

	.content_box_t>div:nth-of-type(1) {
		font-family: HYLiLiangHeiJ;
		font-weight: bold;
		font-size: 0.7rem;
		color: #13385A;

	}

	.content_box_t>div:nth-of-type(2) {
		width: 1.15rem;
		height: 0.16rem;
		background: #6F9EB2;
		border-radius: 0.08rem;
		margin-left: 0.1rem;
	}

	.image1_content1 {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 3.3rem;

	}

	.image1_wx {
		font-family: Adobe Heiti Std;
		font-weight: normal;
		font-size: 0.36rem;
		color: #458099;
		position: absolute;
		bottom: 0.3rem;
	}

	.content1_box_t {
		position: absolute;
		top: 0.6rem;
		left: 1rem;
	}

	.content1_box_t>div:nth-of-type(1) {
		font-family: HYLiLiangHeiJ;
		font-weight: bold;
		font-size: 0.7rem;
		color: #13385A;

	}

	.content1_box_t>div:nth-of-type(2) {
		width: 1.15rem;
		height: 0.16rem;
		background: #6F9EB2;
		border-radius: 0.08rem;
		margin-left: 0.1rem;
	}

	.ourserver_imgs {
		width: 16.95rem;
		height: 5.17rem;
		position: absolute;
		top: -0.60rem;
		left: 1.5rem;

	}
</style>
<style>
	.about_us_N>p>span {
		display: block;
		line-height: 0.46rem !important;
	}

	.ourserver_N>p>span {
		display: block;
		line-height: 0.46rem !important;
	}

	.commitment_N>p>span {
		display: block;
		line-height: 0.46rem !important;
	}
</style>