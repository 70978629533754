export const m = {
	aboutus: 'About Us',
	ourfw: 'Our services',
	ourcn: 'Our Commitment',
	ourlx: 'Contact Us',
	lxfs: 'contacts',
	fslx:"Please contact us through the following methods：",
	yx:"mailbox",
	wx:"WeChat",
	db:"telegram",
}