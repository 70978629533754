export const m = {
	aboutus: '關於我們',
	ourfw: '我們的服務',
	ourcn: '我們的承諾',
	ourlx: '聯繫我們',
	lxfs: '聯繫方式',
	fslx:"請通過以下管道聯繫我們：",
	yx:"郵箱",
	wx:"微信",
	db:"電報",
}