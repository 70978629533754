export const m = {
	aboutus: '关于我们',
	ourfw: '我们的服务',
	ourcn: '我们的承诺',
	ourlx: '联系我们',
	lxfs: '联系方式',
	fslx:"请通过以下方式联系我们",
	yx:"邮箱",
	wx:"微信",
	db:"电报",
}