import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n' //引入对应的组件
import router1 from './router/index1'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(VueI18n); //挂载使用i18n的插件
Vue.config.productionTip = false
const i18n = new VueI18n({
	locale: 'zh-CN', // 语言标识
	messages: {
		'zh-CN': require('./common/lang/zh'), // 中文语言包
		'en-US': require('./common/lang/en'), // 英文语言包
		'en-F': require('./common/lang/fn') // 英文语言包
	}
})

const ISMOBILE = function() {
	const flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	return flag;
}
window.onload = function() {

	if (ISMOBILE()) {
		getRem(750, 100)
	} else {
		getRem(1920, 100)
	}
};
window.onresize = function() {
	if (ISMOBILE()) {
		getRem(750, 100)
	} else {
		getRem(1920, 100)
	}
};

function getRem(pwidth, prem) {
	var html = document.getElementsByTagName("html")[0];
	var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
	html.style.fontSize = oWidth / pwidth * prem + "px";
}

new Vue({
	router: ISMOBILE() ? router1 : router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')