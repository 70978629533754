<template>
	<div id="app">
		<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
		<router-view />
	</div>
</template>
<script>
	export default {
		data() {
			return {}
		},
		created() {
			


		},
		methods: {},

	}
</script>
<style>
	body {
		margin: 0;
		padding: 0;
	}
</style>